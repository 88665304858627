/* 
// 09. Counter
*/

.counter-box {
    background-color: #CFC8F1;
    border-radius: 20px;
    height: 390px;
    padding: 10px;
    .counter-icon {
        background: rgba($white, 0.02);
        border-radius: 50%;
        line-height: 90px;
        text-align: center;
        font-size: 46px;
        box-shadow: 0 3px 0px rgba($white, 0.20);
    }
}


.counter-title {
    margin-top: 10px;
}