/* 
// 03.Components
*/

.btn {
    border-radius: 4px;
    padding: 12px 34px;
    font-size: 15px !important;
    transition: all 0.5s;
    &:focus {
        box-shadow: none !important;
    }
    &:hover {
        transform: translateY(-2px);
        transition: all 0.5s;
    }
    &.btn-lg {
        padding: 10px 22px !important;
        font-size: 18px !important;
    }
    &.btn-sm {
        padding: 8px 16px !important;
        font-size: 14px !important;
    }
    @each $color,
    $value in $theme-colors {
        &.btn-#{$color} {
            background-color: #{$value} !important;
            border: 1px solid #{$value} !important;
            color: $white;
            box-shadow: 0 16px 16px -8px rgba($black, 0.16);
            &:hover,
            &:focus,
            &.focus,
            &:active,
            &.active {
                background-color: #{darken($value, 3%)}!important;
                border:1px solid #{darken($value, 3%)}!important;
                color: $white !important;
            }
        }
        &.btn-outline-#{$color} {
            border: 1px solid #{$value} !important;
            color: #{$value} !important;
            &:hover,
            &:focus,
            &.focus,
            &:active,
            &.active {
                background-color: #{$value} !important;
                border:1px solid #{$value} !important;
                box-shadow: 0 3px 10px rgba($value, 0.5);
                color: $white !important;
            }
        }
    }
    &.btn-light {
        background-color: $light;
        border: 1px solid $light;
        color: $dark !important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: lighten($light, 3%);
            border: 1px solid lighten($light, 3%);
            box-shadow: 0 0 0 2px rgba($light, .08);
            color: $dark !important;
        }
    }
    &.btn-outline-light {
        border: 1px solid $light;
        color: $light !important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $light;
            border: 1px solid $light;
            color: $dark !important;
        }
    }
}

.find-out-btn {
    width: 205px;
}